import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';

const CustomModal = ({ isShow, handleHide, handleShow, children }) => {
    try {
        return (
            <Modal show={isShow} onHide={handleHide} onShow={handleShow}>
                <ModalBody>
                    {children}
                </ModalBody>
            </Modal>
        );
    } catch (e) {
        console.error(e);
    }
};

export default CustomModal;
