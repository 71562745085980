import { memo } from "react";
import Header from "../Main/Header";
import Footer from "../Main/Footer";

const MasterLayouts = ({ children, ...props }) => {
    return (
        <div {...props}>
            <div className="container">
                <div className="main">
                    <Header />
                    {children}
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default memo(MasterLayouts);