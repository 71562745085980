import React from 'react';
import SuKien from "../../img/avatar.png";
import parseHtml from 'html-react-parser';

const index = (title, content) => {
    const Comment = () => {
        return (
            // <div class="d-flex align-items-center mb-3">
            //     <div class="post-image d-none d-sm-block"><img src="/images/heads/Small426.png" alt="2873" />
            //         <div class="post-author text-dark">daklak111</div>
            //     </div>
            //     <div class="post-detail flex-fill">
            //         <div class="d-flex align-items-center justify-content-between"><small class="fw-semibold"
            //             style={{ color: "rgb(255, 148, 7)" }}>daklak111 - Thành viên Vàng</small>
            //             <div class="post-date">06:23:52 11/12/2023</div>
            //         </div>
            //         <div class="post-content mt-0">Và ác daklak111.tạo nv lv2 ko có kiếm gỗ đánh bù nhìn</div>
            //     </div>
            // </div>
            null
        )
    }

    if (title != null && content != null) {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="post-image d-none d-sm-block"><img src={SuKien} alt={title} />
                            <div className="post-author">Admin</div>
                        </div>
                        <div className="post-detail flex-fill">
                            <div className="fw-bold text-primary">{title}</div>
                            {parseHtml(content)}
                            <div className="post-info mt-2">0 lượt xem, 0 bình luận</div>
                        </div>
                    </div>
                    <hr className="my-3" />

                    <div className="d-flex align-items-center mb-3">
                        <div className="post-image d-none d-sm-block"></div>
                        <div className="flex-fill"><textarea className="form-control comment-input" placeholder="Viết bình luận..."></textarea>
                            <div className="d-flex justify-content-end">
                                <button className="px-2 py-1 fw-semibold text-secondary bg-warning bg-opacity-25 border border-warning border-opacity-75 rounded-2 link-success cursor-pointer mt-2">Gửi bình luận</button>
                            </div>
                        </div>
                    </div>

                    <Comment />

                </div>
            </div>
        );
    }

    return null;
};

export default index;