import Notiflix from "notiflix";
import { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Config } from "../../../Config";
import axios from "axios";

const Changepassword = () => {
    const accessToken = localStorage.getItem("access_token") ?? null;
    // const apiUrl = Config
    useEffect(() => {
        document.title = 'Đổi mật khẩu';
    }, []);

    useEffect(() => {
        if (!accessToken) {
            window.location.href = '/home'
        }
    }, [accessToken]);

    const submitChange = async (e) => {
        e.preventDefault();
        Notiflix.Loading.circle();
        const { current_password, password, confirm_password } = document.forms[0];
        try {
            if (!accessToken) {
                return;
            }

            if (
                current_password.value &&
                password.value &&
                confirm_password.value
            ) {
                const response = await axios.post(
                    Config.changepass,
                    {
                        current_password: current_password.value,
                        password: password.value,
                        confirm_password: confirm_password.value,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.data.status === true) {
                    Notiflix.Report.success(
                        "Thông báo",
                        response.data.message,
                        "Đóng",
                        function cb() {
                            localStorage.clear();
                            window.location.href = "/home";
                        }
                    )
                } else {
                    Notiflix.Report.failure(
                        "Thông báo",
                        response.data.message,
                        "Đóng"
                    )
                }

            }
        } catch (error) {
            // console.log(error);
        } finally {
            Notiflix.Loading.remove();
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="mb-3">
                    <div className="row text-center justify-content-center row-cols-3 row-cols-lg-6 g-1 g-lg-1">
                        <div className="col"><Link className="btn btn-sm btn-warning w-100 fw-semibold false" to="/user/profile" style={{ backgroundColor: 'rgb(255, 180, 115)' }}>Tài khoản</Link></div>
                        <div className="col"><Link className="btn btn-sm btn-warning w-100 fw-semibold false" to="/user/transactions" style={{ backgroundColor: 'rgb(255, 180, 115)' }}>Lịch sử GD</Link></div>
                    </div>
                </div>
                <hr />
                <div className="w-100 d-flex justify-content-center">
                    <form className="pb-3" style={{ width: '26rem' }} onSubmit={submitChange}>
                        <div className="fs-5 fw-bold text-center mb-3">Đổi mật khẩu</div>
                        <div className="mb-2">
                            <div className="input-group"><input required name="current_password" type="text" autoComplete="off"
                                placeholder="Nhập mật khẩu hiện tại" className="form-control form-control-solid" /></div>
                        </div>
                        <div className="mb-2">
                            <div className="input-group"><input required name="password" type="password" autoComplete="off"
                                placeholder="Mật khẩu mới" className="form-control form-control-solid" /></div>
                        </div>
                        <div className="mb-2">
                            <div className="input-group"><input required name="confirm_password" type="password" autoComplete="off"
                                placeholder="Nhập lại mật khẩu" className="form-control form-control-solid" /></div>
                        </div>
                        <div className="text-center mt-3"><button type="submit" className="me-3 btn btn-primary">Đổi mật khẩu</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default memo(Changepassword);