import { Config } from '../Config';
import { CustomAxios } from './CustomAxios';

const handleUnauthorizedAccess = () => {
    localStorage.clear();
    window.location.reload();
};

export const CustomAuth = async () => {
    const access_token = localStorage.getItem('access_token');

    if (access_token) {
        try {
            const response = await CustomAxios(Config.verify, {}, {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": 'application/json'
            }, 'POST');

            // console.log(response);

            if (response.status === 200) {
                if (!response.data.status) {
                    handleUnauthorizedAccess();
                }
                return response.data;
            } else if (response.status === 401) {
                handleUnauthorizedAccess();
                return null;
            } else {
                // console.error(`HTTP Error: ${response.status}`);
                return null;
            }
        } catch (error) {
            // console.error("Error during authentication:", error);
            if (error.response.status === 401) {
                handleUnauthorizedAccess();
                return null;
            }
        }
    }

    return null;
};
