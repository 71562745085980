import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomAxios } from "../../../Service/CustomAxios";
import { Config } from "../../../Config";
import LoadingImg from "../../../img/loading.gif";

const Community = () => {

    const [isdata, setdata] = useState(false);

    const title = 'Box Zalo';
    useEffect(() => {
        document.title = title;
    }, [title]);

    const getPackage = async () => {
        try {
            const response = await CustomAxios(Config.community, {
            }, {
                Authorization: `Bearer `,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                if (response.data.status === true) {
                    setdata(response.data);
                }
            }
        } catch (error) {
        } finally {
        }
    }

    useEffect(() => {
        getPackage();
    }, []);

    const Quadrants = () => {
        if (isdata) {
            return (
                <React.Fragment>
                    {isdata.data.map((item) => (
                        <div className="col" key={item.id}>
                            <Link to={item.link} target="_blank">
                                <div className="card card-cover h-80 overflow-hidden text-white bg-dark rounded-5 shadow-lg">
                                    <div className="d-flex flex-column h-80 p-5 pb-3 text-white text-shadow-1">
                                        <h3 className="pt-1 mt-5 mb-4 fw-bold">
                                            {item.name}
                                        </h3>
                                        <ul className="d-flex list-unstyled mt-auto">
                                            <li className="me-auto">
                                                <img
                                                    src={item.icon}
                                                    alt="Bootstrap"
                                                    width="32"
                                                    height="32"
                                                    className="rounded-circle border border-white"
                                                />
                                            </li>
                                            <li className="d-flex align-items-center me-3">
                                                <small>{item.social}</small>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                    }
                </React.Fragment>
            );
        }
        return (
            <div className="text-center"><img style={{ maxWidth: "75px" }} src={LoadingImg}></img></div>
        );
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-2">
                        <Quadrants />
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(Community);