import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router";
import Homepage from "./Component/Main/Homepage";
import Page404 from "./Component/ErrorPage/Page404";
import MasterLayouts from "./Component/Layout/MasterLayouts";
import Profile from "./Component/Page/Profile/Profile";
import RechargeBank from "./Component/Page/Recharge/Bank";
import RechargeMomo from "./Component/Page/Recharge/Momo";
import Exchange from "./Component/Page/Exchange/Exchange";
import Transactions from "./Component/Page/Transaction/Transactions";
import RechargeCard from "./Component/Page/Recharge/Card";
import Community from "./Component/Page/Community/Community";
import Download from "./Component/Page/Download/Download";
import TopNap from "./Component/Page/View/Top";
import ChangePassword from "./Component/Page/Profile/ChangePassword";
import Categories from "./Component/Page/View/Categories";
import Post from "./Component/Page/View/Post";
import Register from "./Component/Page/Event/Register";

const renderUserRouter = () => {
    const userRouters = [
        {
            path: "*",
            component: <Page404 />
        },
        {
            path: "/home",
            component: <Homepage />
        },
        {
            path: "/user/profile",
            component: <Profile />
        },
        {
            path: "/recharge/card",
            component: <RechargeCard />
        },
        {
            path: "/recharge/bank",
            component: <RechargeBank />
        },
        {
            path: "/recharge/momo",
            component: <RechargeMomo />
        },
        {
            path: "/exchange",
            component: <Exchange />
        },
        {
            path: "/user/transactions",
            component: <Transactions />
        },
        {
            path: "/community",
            component: <Community />
        },
        {
            path: "/downLoad",
            component: <Download />
        },
        {
            path: "/user/change-password",
            component: <ChangePassword />
        },
        {
            path: "/post/*",
            component: <TopNap />
        },
        {
            path: "/categories/*",
            component: <Categories />
        },
        {
            path: "/npost/*",
            component: <Post />
        },
        {
            path: "/ncategories/*",
            component: <Categories />
        },
        {
            path: "/event/register",
            component: <Register />
        },
    ]

    return (
        <MasterLayouts>
            <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                {
                    userRouters.map((item, key) => {
                        return (
                            <Route key={key} path={item.path} element={item.component} />
                        )
                    })
                }
            </Routes>
        </MasterLayouts>
    )
}

const RouterCustom = () => {
    return renderUserRouter();
}

export default RouterCustom;