import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomAuth } from "../../../Service/CustomAuth";
import LoadingImg from "../../../img/loading.gif"

const Profile = () => {

    const [data, setData] = useState(null);
    const access_token = localStorage.getItem("access_token");

    useEffect(() => {
        document.title = "Thông tin tài khoản";
    }, []);

    const formatInt = (e) => {
        const formattedAmount = new Intl.NumberFormat();
        return formattedAmount.format(e);
    };

    const verify = async () => {
        const user = await CustomAuth();
        if (user !== null) {
            setData(user);
        }
        // console.log(user);
    }

    const showEmail = (e) => {
        e.preventDefault();
        const emailInput = document.getElementById('emailProfile');

        if (emailInput.getAttribute('type') === "password") {
            emailInput.setAttribute('type', 'email');
        } else {
            emailInput.setAttribute('type', 'password');
        }

        if (e.target.tagName === 'BUTTON') {
            if (e.target.textContent === 'Hiển thị') {
                e.target.textContent = 'Ẩn Email';
            } else if (e.target.textContent === 'Ẩn Email') {
                e.target.textContent = 'Hiển thị';
            }
        }
    }


    useEffect(() => {
        if (access_token) {
            verify();
        } else {
            window.location.href = "/home"
        }
    }, [])

    const TableUser = () => {
        if (data !== null) {
            return (
                <table className="table">
                    <tbody>
                        <tr className="fw-semibold">
                            <td>Tài khoản</td>
                            <td>{data?.data.username}</td>
                        </tr>
                        <tr className="fw-semibold">
                            <td>Mật khẩu</td>
                            <td>****** (<Link to="/user/change-password">Đổi mật khẩu</Link>)</td>
                        </tr>
                        <tr className="fw-semibold">
                            <td>Số dư</td>
                            <td>{formatInt(data?.data.balance)} PCoin</td>
                        </tr>
                        <tr className="fw-semibold">
                            <td>Lượng</td>
                            <td>{formatInt(data?.data.luong)} Lượng</td>
                        </tr>
                        {/* <tr className="fw-semibold">
                            <td>Số điện thoại</td>
                            <td>{data?.data.phone} (<Link to="/user/change-phone">Thay đổi</Link>)</td>
                        </tr> */}
                        <tr className="fw-semibold">
                            <td>Email</td>
                            <td>
                                <div className="input-group">
                                    <input type="password" className="form-control" disabled={true} id="emailProfile" defaultValue={data?.data.email} />
                                    <button type="button" className="btn btn-primary" onClick={showEmail}>Hiển thị</button>
                                </div>
                            </td>
                        </tr>
                        {/* <tr className="fw-semibold">
                            <td>Link giới thiệu</td>
                            <td><span className="cursor-pointer text-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                                title="Copy">Click để copy</span></td>
                        </tr> */}
                        <tr className="fw-semibold">
                            <td>Nhóm thành viên</td>
                            <td style={{ color: 'black' }}>Thành viên</td>
                        </tr>
                        <tr className="fw-semibold">
                            <td>Trạng thái</td>
                            <td className={data?.data.status === 2 ? 'text-danger fw-bold' : 'text-success fw-bold'}>{data?.data.status === 2 ? 'Block' : 'Hoạt Động'}</td>
                        </tr>
                        <tr className="fw-semibold">
                            <td>Trạng thái Game</td>
                            <td className={data?.data?.isOnline === true ? 'text-success fw-bold' : 'text-danger fw-bold'}>{data?.data?.isOnline === true ? 'Online' : 'Offline'}</td>
                        </tr>
                        <tr className="fw-semibold">
                            <td>Ngày tham gia</td>
                            <td>{data?.data.created_at}</td>
                        </tr>
                    </tbody>
                </table>
            )
        } else {
            return (
                <div className="text-center"><img style={{ maxWidth: "75px" }} src={LoadingImg}></img></div>
            )
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="mb-3">
                    <div className="row text-center justify-content-center row-cols-3 row-cols-lg-6 g-1 g-lg-1">
                        <div className="col"><Link className="btn btn-sm btn-warning w-100 fw-semibold active" to="/user/profile"
                            style={{ backgroundColor: 'rgb(255, 180, 115)' }}>Tài khoản</Link></div>
                        <div className="col"><Link className="btn btn-sm btn-warning w-100 fw-semibold false" to="/user/transactions"
                            style={{ backgroundColor: 'rgb(255, 180, 115)' }}>Lịch sử GD</Link></div>
                    </div>
                </div>
                <hr />
                <TableUser />
            </div>
        </div>
    )
}

export default Profile;