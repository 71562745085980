import React from 'react';
import agerule from "../../img/age-rule.jpg";

const Footer = () => {
    return (
        <div className="text-center my-2">
            <div className="age-rule"><img src={agerule} alt="Age Rule" height="12" /><span>Trò chơi dành cho
                người chơi 12 tuổi trở lên. Chơi quá 180 phút mỗi ngày sẽ có hại cho sức khỏe</span></div>
        </div>
    );
};

export default Footer;