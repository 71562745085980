import { memo, useEffect, useState } from "react";
import { Config } from "../../../Config";
import { CustomAxios } from "../../../Service/CustomAxios";
import Notiflix from "notiflix";
// import axios from "axios";

const DownLoad = () => {

    useEffect(() => {
        document.title = "Tải game";
    }, []);
    const [version, setversion] = useState(true);
    const [downloadG, setdownloadG] = useState([]);
    const [loading, setloading] = useState(false);
    const [browser, setBrowser] = useState('');
    const getPackage = async () => {
        try {
            setloading(true);
            const response = await CustomAxios(Config.download, {
            }, {
                // Authorization: ``,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                if (response.data.status === true) {
                    setdownloadG(response.data);
                }
            }
        } catch (error) {
            Notiflix.Report.failure(
                "Thông báo",
                "Lỗi kết nối internet",
                "Đồng ý"
            )
        } finally {
            setloading(false);
        }
    }

    const errorGetLink = () => {
        Notiflix.Report.failure(
            "Thông báo",
            "Lỗi lấy link tải, vui lòng thử lại",
            "Đồng ý"
        )
    }

    const downloadFile = async (urlPam) => {
        // window.location.href = atob(urlPam);
        // console.log(atob(urlPam));
        try {
            setloading(true);
            const response = await CustomAxios(Config.geturldownload, {
                download: urlPam
            }, {
                // Authorization: ``,
                "Content-Type": 'application/json'
            }, 'POST');
            console.log(response.data.download, atob(response.data.download));
            if (response.status === 200) {
                if (response.data.status === true && response.data.download !== null) {
                    window.location.href = atob(response.data.download);
                    return;

                } else {
                    errorGetLink();
                }
            } else {
                errorGetLink();
            }
        } catch (error) {
            Notiflix.Report.failure(
                "Thông báo",
                "Lỗi kết nối internet",
                "Đồng ý"
            )
        } finally {
            setloading(false);
        }
    };

    const unpackBase64 = (download) => {
        window.location.href = atob(download);
        return;
    }

    useEffect(() => {
        const userAgent = navigator.userAgent;
        let detectedBrowser = '';

        if (userAgent.match(/Android/i)) {
            detectedBrowser = 'Android';
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            detectedBrowser = 'iOS';
        } else {
            detectedBrowser = 'PC';
        }

        setBrowser(detectedBrowser);
    }, []);

    useEffect(() => {
        getPackage();
    }, []);

    useEffect(() => {
        document.title = 'Tải game';
    }, []);

    useEffect(() => {
        if (loading) {
            Notiflix.Loading.circle();
        } else {
            Notiflix.Loading.remove();
        }
    }, [loading]);

    console.log(browser);

    const classNames = ["suggestion", "false"];
    // Kiểm tra nếu có dữ liệu và có phần tử trong mảng Java
    const providersList = downloadG.java?.map((provider, index) => (
        <div className="col-md-4 mt-1" key={index}>
            <div className={`card download-bg`}>
                <div className="card-body text-center">
                    <h5 className="card-title">{provider.title}</h5>
                    <p className="card-text">{provider.content}</p>
                    <button
                        className="btn btn-danger me-1 mt-1"
                        onClick={() => downloadFile(provider.download)}
                    >
                        Tải về
                    </button>
                </div>
            </div>
        </div>
    ));
    const providersListjava = downloadG.java?.map((provider, index) => (
        <button
            className="btn btn-danger me-1 mt-1"
            onClick={() => downloadFile(provider.download)}
            key={index}
        >
            {provider.title}
        </button>
    ));
    const providersListjavaD = downloadG.javad?.map((provider, index) => (
        <button
            className="btn btn-danger me-1 mt-1"
            onClick={() => downloadFile(provider.download)}
            key={index}
        >
            {provider.title}
        </button>
    ));
    const providersListAndroid = downloadG.android?.map((provider, index) => (
        <button
            className="btn btn-danger me-1 mt-1"
            onClick={() => downloadFile(provider.download)}
            key={index}
        >
            {provider.title}
        </button>
    ));
    const providersListPC = downloadG.pc?.map((provider, index) => (
        <button
            className="btn btn-danger me-1 mt-1"
            onClick={() => downloadFile(provider.download)}
            key={index}
        >
            {provider.title}
        </button>
    ));
    const providersListIos = downloadG.ios?.map((provider, index) => (
        <button
            className="btn btn-danger me-1 mt-1"
            onClick={() => downloadFile(provider.download)}
            key={index}
        >
            {provider.title}
        </button>
    ));
    const providersListOr = downloadG.order?.map((provider, index) => (
        <button
            className="btn btn-danger me-1 mt-1"
            onClick={() => downloadFile(provider.download)}
            key={index}
        >
            {provider.title}
        </button>
    ));
    const providersListTest = downloadG.testflight?.map((provider, index) => (
        <button
            className="btn btn-danger me-1 mt-1"
            onClick={() => unpackBase64(provider.download)}
            key={index}
        >
            {provider.title}
        </button>
    ));
    const Dexuattai = () => {
        if (browser === "PC") {
            return (
                <>
                    <div className="fw-semibold text-center h5">TẢI GAME CHO PC</div>
                    <div className="text-center"><span className="fw-semibold">Cách 1: Phiên bản thuần PC, tải về chơi ngay</span>
                        <div>
                            {providersListPC}
                        </div>
                    </div>
                    <div className="text-center mt-4"><span className="fw-semibold">Cách 2: Chạy giả lập Java (Độ ổn định cao, nhiều tính
                        năng)</span>
                        <div>
                            {providersListjava}
                        </div>
                        <div className="mt-4">
                            <div className="h6">Hướng dẫn cách cài đặt</div>
                            <div>Bước 1: Tải Microemulator: <a
                                href="https://angelchip.net/files/share/AngelChipEmulatorEXE.zip">AngelChipEmulator.zip</a>
                            </div>
                            <div>Bước 2: Tải một trong các phiên bản bên trên (Gợi ý: bản 211)</div>
                            <div>Bước 3: Giải nén file AngelChipEmulator.zip đã tải ở bước 1</div>
                            <div>Bước 4: Mở ứng dụng AngelChipEmulator.exe ở thư mục đã giải nén</div>
                            <div>Bước 5: Kéo file game có đuôi .jar đã tải ở trên vào và bấm Start</div>Trước khi bấm Start các bạn
                            căn chỉnh lại kích thước sao cho dễ chơi nhất nhé
                        </div>
                    </div>
                </>
            )
        } else if (browser === "Android") {
            return (
                <>
                    <div className="fw-semibold text-center h5">TẢI GAME CHO ANDROID</div>
                    <div className="text-center"><span className="fw-semibold">Cách 1: Tải phiên bản APK, tải về chơi ngay</span>
                        <div>{providersListAndroid}</div>
                    </div>
                    <div className="text-center mt-4"><span className="fw-semibold">Cách 2: Chạy giả lập J2ME Loader (Nhiều tính năng)</span>
                        <div>{providersListjava}</div>
                    </div>
                    <div className="text-center mt-4">
                        {/* <span className="fw-semibold">Cách 2: Chạy giả lập J2ME Loader (Nhiều tính năng)</span> */}
                        <div className="mt-4">
                            <div className="h6">Hướng dẫn cách cài đặt</div>
                            <div>Bước 1: Lên CHPlay tải J2Me Loader hoặc tải <a
                                href="https://play.google.com/store/apps/details?id=ru.playsoftware.j2meloader&amp;hl=vi&amp;gl=US">tại
                                đây</a></div>
                            <div>Bước 2: Tải một trong các phiên bản bên trên về máy</div>
                            <div>Bước 3: Bấm mở trực tiếp phiên bản đã tải về hoặc vào J2MeLoader bấm dấu cộng, sau đó tìm phiên bản đã tải
                                về rồi chạy</div>
                            <div>Trước khi chạy, các bạn nhớ cài đặt màn hình, bàn phím ảo theo ý thích nhé</div>
                            <div>Video hướng dẫn: <a href="https://www.youtube.com/shorts/8ajySmPA-rQ">click để xem</a></div>
                        </div>
                    </div>
                </>
            )
        } else if (browser === "iOS") {
            return (
                <>
                    <div className="fw-semibold text-center h5">TẢI GAME CHO IOS</div>
                    <div className="text-center"><span className="fw-semibold">Phiên bản 1: TestFlight</span>
                        <div>{providersListTest}
                            <div>Phiên bản này cài đặt dễ dàng, dành cho anh em IOS</div>
                        </div>
                    </div>
                    <div className="text-center"><span className="fw-semibold">Phiên bản 2: File Ipa</span>
                        <div>{providersListIos}
                            <div>Phiên bản này dành cho anh em muốn trải nghiệm đồ họa cao, sắc nét, xem được cả trang bị 2, nhưng có
                                thể sẽ có tình trạng nóng máy, anh em phải biết cài File Ipa mới tải bản này</div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        {/* <span className="fw-semibold">Phiên bản 2: File Ipa</span> */}
                        <div className="mt-4"><div className="h6">Làm sao để cài đặt?</div><div>Anh em chọn phiên bản TestFlight ở trên sẽ ra một đường dẫn của TestFlight, anh em làm theo từng bước 1 ở trong trang hướng dẫn là có thể tải nhé, đối với những anh em không bấm được bước 2 thì copy CODE bên trên để nhập mã quy đổi trong TestFlight là được nhé</div></div>
                    </div>
                </>
            )
        }
    }


    return version === true ? (
        <div className="card">
            <div className="card-body">
                {/* {Dexuattai} */}
                <Dexuattai />
                <div
                    className="mt-4 text-center fw-semibold text-primary cursor-pointer"
                    onClick={() => setversion(false)}
                >
                    Xem tất cả phiên bản
                </div>
            </div>
        </div>
    ) : (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    {/* {providersList} */}
                    <div className="col-md-4 mt-1">
                        <div className={`card download-bg suggestion`}>
                            <div className="card-body text-center">
                                <h5 className="card-title">Tải game cho Java (JAR)</h5>
                                <p className="card-text">Nhấn vào đây để tải game cho giả lập Java của bạn.</p>
                                {providersListjava}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-1">
                        <div className={`card download-bg suggestion`}>
                            <div className="card-body text-center">
                                <h5 className="card-title">Tải game cho Java (JAD)</h5>
                                <p className="card-text">Nhấn vào đây để tải game cho điện thoại Java của bạn.</p>
                                {providersListjavaD}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-1">
                        <div className={`card download-bg false`}>
                            <div className="card-body text-center">
                                <h5 className="card-title">Tải game cho Android</h5>
                                <p className="card-text">Nhấn vào đây để tải game cho điện thoại Android của bạn.</p>
                                {providersListAndroid}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-1">
                        <div className={`card download-bg suggestion`}>
                            <div className="card-body text-center">
                                <h5 className="card-title">Tải game cho PC</h5>
                                <p className="card-text">Nhấn vào đây để tải game cho máy tính để bàn của bạn.</p>
                                {providersListPC}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-1">
                        <div className={`card download-bg false`}>
                            <div className="card-body text-center">
                                <h5 className="card-title">Tải game cho IOS (IPA)</h5>
                                <p className="card-text">Nhấn vào đây để tải game cho điện thoại IOS của bạn.</p>
                                {providersListIos}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-1">
                        <div className={`card download-bg false`}>
                            <div className="card-body text-center">
                                <h5 className="card-title">Tải game cho IOS (TestFlight)</h5>
                                <p className="card-text">Nhấn vào đây để tải game cho điện thoại IOS của bạn.</p>
                                {providersListTest}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-1">
                        <div className={`card download-bg suggestion`}>
                            <div className="card-body text-center">
                                <h5 className="card-title">Phiên Bản Khác</h5>
                                <p className="card-text">Nhấn vào đây để tải game cho điện thoại của bạn.</p>
                                {providersListOr}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="mt-4 text-center fw-semibold text-primary cursor-pointer"
                    onClick={() => setversion(true)}
                >
                    Xem phiên bản thích hợp
                    {/* Xem hướng dẫn */}
                </div>
            </div>
        </div>
    );
}

export default memo(DownLoad);