import axios from 'axios';

export const CustomAxios = (url, data, header, method = "GET" | "POST") => {
    let config = {
        headers: header // You can include any additional headers here
    };

    let axiosPromise;

    if (method === 'POST') {
        axiosPromise = axios.post(url, data, config);
    } else {
        axiosPromise = axios.get(url, config);
    }

    axiosPromise.then((response) => {
    }).catch((error) => {
    });

    return axiosPromise;
};
