import { memo, useEffect, useState } from "react";
import SuKien from "../../../img/avatar.png";
import parseHtml from 'html-react-parser';
import { Config } from "../../../Config";
import { CustomAxios } from "../../../Service/CustomAxios";

const CategoriesView = () => {

    const [DataPost, setDataPost] = useState([]);

    useEffect(() => {
        const getDataPost = async () => {
            try {
                const geturl = window.location.pathname;
                const parts = geturl.split('/');
                const filteredParts = parts.filter(part => part !== '');
                const result = filteredParts.length >= 2 ? filteredParts[1] : null;
                const response = await CustomAxios(`${Config.postview + result}`,
                    {
                        type: 'post',
                        id: result
                    },
                    {
                        Authorization: 'Bearer ',
                        "Content-Type": 'application/json'
                    }, 'POST');

                if (response.status === 200) {
                    setDataPost(response.data);
                }
            } catch (error) {
            } finally {
            }
        };

        getDataPost();
    }, []);


    const parseHtmlShow = () => {
        const content = DataPost?.content;
        return content ? parseHtml(content) : null;
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="post-image d-none d-sm-block"><img src={SuKien} alt={DataPost?.title} />
                        <div className="post-author">Admin</div>
                    </div>
                    <div className="post-detail flex-fill">
                        <div className="fw-bold text-primary">{DataPost?.title}</div>
                        {parseHtmlShow()}
                        <div className="post-info mt-2">{DataPost?.views} lượt xem, 20 bình luận</div>
                    </div>
                </div>
                <hr className="my-3" />
            </div>
        </div>
    )
}

export default memo(CategoriesView);