import { memo, useEffect, useState } from "react";
import { Config } from "../../../Config";
import { CustomAxios } from "../../../Service/CustomAxios";
import LoadingImg from "../../../img/loading.gif";

const TopNap = () => {
    let stringToRemove = "/post/";
    let resultString = window.location.pathname.replace(stringToRemove, "");
    const [datane, setdata] = useState({});
    useEffect(() => {
        return () => {
            document.title = "Top nạp";
        };
    }, []);

    const SendApi = async () => {
        try {
            const response = await CustomAxios(Config.topnap + resultString, {
                type: resultString,
            }, {
                Authorization: `Bearer `,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200 && response.data.status === true) {
                setdata(response.data);
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/home";
        }
    };

    useEffect(() => {
        SendApi();
    }, [resultString]);

    if (datane.status === false) {
        return (
            <div className="fs-5 fw-semibold text-center">
                <h6 style={{ color: "rgb(0 0 0)" }}>
                    Không có ai đứng top
                </h6>
            </div>
        );
    }

    const renderTable = () => {
        if (datane.type === "topnap") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>PCoin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (datane.type === "topluong") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>Lượng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (datane.type === "topboss") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>Point B</th>
                            <th>Total Damage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.pointB}</td>
                                <td>{item.totalDame}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (datane.type === "topxu") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>Xu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (datane.type === "topyen") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>Yên</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (datane.type === "lucchien") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>Total Damage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }} >
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.totalDame}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }else if (datane.type === "naptuan") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>PCoin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }else if (datane.type === "napthang") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>PCoin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }else if (datane.type === "nganphieutuan") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Top</th>
                            <th>Username</th>
                            <th>Ngân phiếu</th>
                            <th>Lượng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.top} style={{ color: item.colorText }}>
                                <td>{item.top}</td>
                                <td>{item?.username || "player***"}</td>
                                <td>{item.amount}</td>
                                <td>{item.receive}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }

        return (
            <div className="text-center"><img style={{ maxWidth: "75px" }} src={LoadingImg}></img></div>
        ); // Return null if type is not recognized
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="table-responsive">{renderTable()}</div>
            </div>
        </div>
    );

}

export default memo(TopNap);