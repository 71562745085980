import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Config } from '../../../Config';
import { CustomAxios } from '../../../Service/CustomAxios';
import Notiflix from 'notiflix';

const Register = () => {

    const [eventStatus, seteventStatus] = useState(false);
    const access_token = localStorage.getItem("access_token");
    const [errormsg, seterrormsg] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (loading) {
            Notiflix.Loading.circle();
            return;
        }

        if (!loading) {
            Notiflix.Loading.remove();
            return;
        }
    }, [loading])

    useEffect(() => {
        document.title = "Tham gia event";
    }, [document.title]);

    const checkevent = async () => {
        try {
            const response = await axios.post(
                Config.checkevent,
                {},
                {
                    headers: {
                        Authorization: `Bearer `,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200 && response.data.status === true) {
                seteventStatus(response.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const submitRegister = async (e) => {
        e.preventDefault();
        const { username, email, password, confirm_password } = document.forms[0];

        const u = username.value;
        const em = email.value;
        const p = password.value;
        const cp = confirm_password.value;

        if (u && em && p && cp) {
            try {
                setLoading(true);
                const response = await CustomAxios(Config.register,
                    {
                        username: u,
                        password: p,
                        email: em,
                        isEvent: true,
                        password_confirmation: cp,
                    },
                    {
                        Authorization: 'Bearer ',
                        "Content-Type": 'application/json'
                    }, 'POST');

                if (response.status === 200) {
                    if (response.data.status === false) {
                        seterrormsg(response.data.message);
                    }

                    if (response.data.status === true) {
                        localStorage.setItem("access_token", response.data.access_token);
                        window.location.href = "/home";
                    }
                }
            } catch (error) {
                Notiflix.Report.failure(
                    "Thông báo",
                    "Lỗi kết nối internet",
                    "Đồng ý"
                )
            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            seterrormsg(null);
        }, 2000);
    }, [errormsg]);

    useEffect(() => {
        checkevent();
    }, []);

    if (eventStatus !== null) {
        return (
            <div className="card">
                <div className="card-body">
                    <div>

                        <div className="w-100 d-flex justify-content-center">

                            <form action="#" className="pb-3" style={{ width: '26rem' }} onSubmit={submitRegister}>
                                <div className="fw-semibold fs-5 my-3 text-center">Đăng kí tài khoản event {eventStatus?.name}</div>
                                {errormsg && <div className="alert alert-danger">{errormsg}</div>}

                                <div className="mb-2"><label className="fw-semibold">Tài khoản</label>
                                    <div className="input-group">
                                        <input required name="username" type="text" autoComplete="off" placeholder="Nhập tên tài khoản"
                                            className="form-control form-control-solid" />
                                    </div>
                                </div>
                                <div className="mb-2"><label className="fw-semibold">Email</label>
                                    <div className="input-group">
                                        <input required name="email" type="email" autoComplete="off" placeholder="Nhập email"
                                            className="form-control form-control-solid" />
                                    </div>
                                </div>
                                <div className="mb-2"><label className="fw-semibold">Mật khẩu</label>
                                    <div className="input-group">
                                        <input required name="password" type="password" autoComplete="off"
                                            placeholder="Nhập mật khẩu" className="form-control form-control-solid" />
                                    </div>
                                </div>
                                <div className="mb-2"><label className="fw-semibold">Nhập lại mật khẩu</label>
                                    <div className="input-group">
                                        <input required name="confirm_password" type="password" autoComplete="off" placeholder="Nhập lại mật khẩu"
                                            className="form-control form-control-solid" />
                                    </div>
                                </div>
                                <div className="text-center mt-3"><button type="submit" className="me-3 px-3 btn btn-primary">Xác nhận</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        // window.location.href = "/home";
    }

};

export default Register;