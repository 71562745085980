import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Config } from "../../../Config";
import { CustomAxios } from "../../../Service/CustomAxios";

const Transactions = () => {
    const title = 'Lịch sử giao dịch';
    useEffect(() => {
        document.title = title;
    }, [title]);
    const [dataTransaction, setdataTransaction] = useState([]);
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
    const [currentPage, setCurrentPage] = useState(1);
    const access_token = localStorage.getItem("access_token");

    const getDataTelco = async () => {

        try {
            const response = await CustomAxios(Config.transaction, {
            }, {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                setdataTransaction(response.data);
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/home";
        } finally {
        }
    }

    useEffect(() => {
        if (access_token) {
            // call function
            getDataTelco();
        } else {
            window.location.href = "/home"
        }
    }, [access_token]);

    const formatNumber = (number, options) => {
        return new Intl.NumberFormat('vi-VN', options).format(number);
    };

    const handleSort = (column) => {
        if (sortedColumn === column) {
            // Toggle sort direction if the same column is clicked again
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            // Set the new sorted column and default to ascending order
            setSortedColumn(column);
            setSortDirection('asc');
        }
    };

    // Calculate the index range for the current page
    const startIndex = (currentPage - 1) * 5;
    const endIndex = startIndex + 5;

    const sortedData = dataTransaction.slice().sort((a, b) => {
        // Customize the sorting logic based on the data type and column
        switch (sortedColumn) {
            case 'ID':
                return (sortDirection === 'asc' ? a.id - b.id : b.id - a.id);
            case 'Số tiền':
                return (sortDirection === 'asc' ? a.net_amount - b.net_amount : b.net_amount - a.net_amount);
            case 'Sau G.D':
                return (sortDirection === 'asc' ? a.balance_after - b.balance_after : b.balance_after - a.balance_after);
            case 'Mô tả':
                return (sortDirection === 'asc' ? a.description - b.description : b.description - a.description);
            // Add more cases for other columns as needed
            case 'Ngày tạo':
                return (sortDirection === 'asc' ? a.created_at - b.created_at : b.created_at - a.created_at);
            default:
                return '0';
        }
    });

    // Extract the paginated subset of data
    const paginatedData = sortedData.slice(startIndex, endIndex);

    const totalPages = Math.ceil(sortedData.length / 5);

    const handlePageChange = (pageNumber, totalNumber) => {
        if (pageNumber > 0 && pageNumber <= totalNumber) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="mb-3">
                    <div className="row text-center justify-content-center row-cols-3 row-cols-lg-6 g-1 g-lg-1">
                        <div className="col"><Link className="btn btn-sm btn-warning w-100 fw-semibold false" to="/user/profile"
                            style={{ backgroundColor: 'rgb(255, 180, 115)' }}>Tài khoản</Link></div>
                        <div className="col"><Link className="btn btn-sm btn-warning w-100 fw-semibold active" to="/user/transactions"
                            style={{ backgroundColor: 'rgb(255, 180, 115)' }}>Lịch sử GD</Link></div>
                    </div>
                </div>
                <hr />
                <div className="table-responsive">
                    <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('ID')}>ID</th>
                                <th onClick={() => handleSort('Số tiền')}>Số tiền</th>
                                <th onClick={() => handleSort('Sau G.D')}>SauG.D</th>
                                <th onClick={() => handleSort('Mô tả')}>Mô tả</th>
                                <th onClick={() => handleSort('Ngày tạo')}>Ngày tạo</th>
                            </tr>
                        </thead>
                        <tbody className="fw-semibold">
                            {dataTransaction.length > 0 ? (
                                <>
                                    {paginatedData.map((row) => (
                                        <tr key={row.id}>
                                            <td>{row.id}</td>
                                            <td><span className="text-success fw-semibold">{formatNumber(row.net_amount)}đ</span></td>
                                            <td><span className="text-danger fw-semibold">{formatNumber(row.balance_after)}đ</span></td>
                                            <td><span className="text-info fw-semibold">{row.description}</span></td>
                                            <td><span className="fw-semibold">{row.created_at}</span></td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="5"><div className="d-flex text-center w-100 align-content-center justify-content-center" bis_skin_checked="1">Không có bản ghi nào</div></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <span>Page {currentPage} of {totalPages}</span>
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div>
                            <ul className="pagination">
                                <li className="page-item"><a className="page-link" style={{ cursor: "pointer" }} onClick={() => handlePageChange(currentPage - 1, totalPages)} disabled={currentPage === 1}>&lt;</a></li>
                                <li className="page-item active"><a className="page-link" style={{ cursor: "pointer" }}>1</a></li>
                                <li className="page-item"><a className="page-link" style={{ cursor: "pointer" }} onClick={() => handlePageChange(currentPage + 1, totalPages)} disabled={currentPage === totalPages}>&gt;</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Transactions);