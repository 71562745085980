
import React, { useEffect, useState } from 'react';
import Avatar from "../../img/avatar2.png";
import { Link } from 'react-router-dom';
import Obito from "../../img/obito.png";
import Img2502 from "../../img/2502.png";
import { Config } from '../../Config';
import { CustomAxios } from '../../Service/CustomAxios';
import POSTHH from "../../Component/Post/index"

const Homepage = () => {

    const [categories, setcategories] = useState(false);
    const [dataPost, setpost] = useState(false);
    const [card, setcard] = useState(null);

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    useEffect(() => {
        document.title = capitalizeFirstLetter(window.location.hostname);
    }, []);

    const getpost = async () => {
        try {
            const response = await CustomAxios(Config.getpost,
                {
                },
                {
                    Authorization: 'Bearer ',
                    "Content-Type": 'application/json'
                }, 'POST');

            if (response.status === 200) {
                setpost(response.data);
            }
        } catch (error) {
        } finally {
        }
    }

    const getcategories = async () => {
        try {
            const response = await CustomAxios(Config.getcategories,
                {
                },
                {
                    Authorization: 'Bearer ',
                    "Content-Type": 'application/json'
                }, 'POST');

            if (response.status === 200) {
                setcategories(response.data);
            }
        } catch (error) {
        } finally {
        }
    }

    const categoriesA = [
        {
            title: "Mốc vip",
            content: "Mốc vip",
            url: "/categories/mocvip",
            class: "text-danger",
            img: Obito
        },
        {
            title: "Top Nạp",
            content: "Xem đua top nạp",
            url: "/post/nap",
            class: "",
            img: Img2502
        }, {
            title: "Top Nạp Tuần",
            content: "Xem đua top nạp tuần",
            url: "/post/naptuan",
            class: "",
            img: Img2502
        }, {
            title: "Top Nạp tháng",
            content: "Xem đua top nạp tháng",
            url: "/post/napthang",
            class: "",
            img: Img2502
        },
        {
            title: "Top ngân phiếu tuần",
            content: "Xem top ngân phiếu tuần",
            url: "/post/nganphieutuan",
            class: "",
            img: Img2502
        },
        {
            title: "Top ngân phiếu tháng",
            content: "Xem top ngân phiếu tháng",
            url: "/post/nganphieuthang",
            class: "",
            img: Img2502
        },
        {
            title: "Top lượng",
            content: "Xem Top lượng",
            url: "/post/luong",
            class: "",
            img: Img2502
        },
        {
            title: "Top xu",
            content: "Xem Top xu",
            url: "/post/xu",
            class: "",
            img: Img2502
        },
        {
            title: "Top yên",
            content: "Xem Top yên",
            url: "/post/yen",
            class: "",
            img: Img2502
        },
        {
            title: "Top săn boss",
            content: "Xem Top săn boss",
            url: "/post/boss",
            class: "",
            img: Img2502
        },
        {
            title: "Top lực chiến",
            content: "Xem Top lực chiến",
            url: "/post/lucchien",
            class: "",
            img: Img2502
        }
    ]

    useEffect(() => {
        getpost();
        getcategories();
    }, []);

    useEffect(() => {
        setcard(<GetCard />)
    }, [dataPost]);

    const NewPost = () => {
        if (dataPost) {
            return dataPost.map((post, key) =>
                <div className="post-item d-flex align-items-center my-2" key={key}>
                    <div className="post-image"><img src={Avatar} alt={post.title} /></div>
                    <div>
                        <Link className={`fw-bold ${post?.status == 1 ? "text-success" : post?.status == 2 ? "text-warning" : post?.status == 3 ? "text-danger" : null}`} onClick={() => viewPost(post.id)}>
                            {post.title}
                        </Link>
                        <div className="text-muted font-weight-bold">Lượt xem: {post.views}, Bình luận: 0</div>
                    </div>
                </div>
            );
        }
        // Return null if dataPost is null
        return null;
    }

    const NewCategories = () => {
        if (categories) {
            return categories.map((post, key) =>
                <div className="post-item d-flex align-items-center my-2" key={key}>
                    <div className="post-image"><img src={Obito} alt={post.title} /></div>
                    <div><Link className="fw-bold text-danger" to={`/ncategories/` + post.id}>{post.title} </Link>
                        <div className="text-muted font-weight-bold">{post.description}</div>
                    </div>
                </div>
            );
        }
        // Return null if dataPost is null
        return null;
    }



    const GetCard = () => {
        return (
            <div className="card-body">
                <div className="card-title h5">Bài viết mới</div>
                <hr />
                <div>
                    <NewPost />
                </div>
                <div className="pt-2 card-title h5">Danh mục</div>
                <hr />
                <div>
                    {
                        categoriesA.map((e, k) => {
                            return (
                                <div key={k} className="post-item d-flex align-items-center my-2">
                                    <div className="post-image"><img src={e.img} alt={e.title} /></div>
                                    <div>
                                        <Link className={`fw-bold ${e.class}`} to={e.url}>{e.title}</Link>
                                        <div className="text-muted font-weight-bold">{e.content}</div>
                                    </div>
                                </div>
                            );
                        })
                    }

                    <NewCategories />
                </div>
                <div className="mt-4">
                    <div className="card-title h5">Giới thiệu</div>
                    <hr />
                    <div className="mx-2 fs-6">{capitalizeFirstLetter(window.location.hostname)} là 1 website game thế giới mở với chủ đề trường học ninja, nơi người chơi sẽ được trải nghiệm cuộc sống của một Ninja thực thụ. Trong game, người chơi có thể tham gia vào các hoạt động giải trí như săn bắn quái vật, khám phá khu rừng bí ẩn, hoặc tham gia đấu trường PvP để thử thách và cạnh tranh với những Ninja khác. Ngoài ra, game còn có nhiều nhiệm vụ và thử thách khác nhau cho người chơi hoàn thành, từ đó thu thập được điểm kinh nghiệm và trang bị vũ khí, trang phục mới. Với đồ họa đẹp mắt, âm thanh sống động và nội dung đa dạng, {window.location.hostname} sẽ đem đến cho người chơi những trải nghiệm tuyệt vời và thỏa mãn niềm đam mê với văn hóa Ninja. </div>

                </div>
            </div>
        )
    }

    const viewPost = (id) => {
        const postData = dataPost.find(post => post.id === id);
        setcard(POSTHH(postData?.title, postData?.content))
    }

    return (
        <div className="card">
            {card}
        </div>
    );
};

export default Homepage;
