import momo from "../../../img/momo.png";
import bank from "../../../img/atm.png";
import card from "../../../img/card.png";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CustomAxios } from "../../../Service/CustomAxios";
import { Config } from "../../../Config";
import LoadingImg from "../../../img/loading.gif";
import Notiflix from "notiflix";

const RechargeBank = () => {

    const [DataBank, setDataBank] = useState(null);
    const [Payment, showPayment] = useState({ status: false });
    const [DataPay, setDataPay] = useState({ status: false });
    const [selectedId, setSelectedId] = useState(0);
    const access_token = localStorage.getItem("access_token");
    const [countdown, setCountdown] = useState(60); // Initial countdown value
    const [isCounting, setIsCounting] = useState(false);

    const title = 'Nạp PCoint';
    useEffect(() => {
        document.title = title;
    }, [title]);

    const formatInt = (e) => {
        const formattedAmount = new Intl.NumberFormat();
        return formattedAmount.format(e);
    };

    useEffect(() => {
        let interval;

        if (isCounting) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        setIsCounting(false); // Stop the countdown when it reaches 0
                        clearInterval(interval);
                        Notiflix.Report.warning(
                            "Thông báo",
                            "Load lại trang để cập nhật số tiền, nếu như chưa cộng tiền, hãy liên hệ admin",
                            "Xác nhận"
                        );
                        return 0; // Reset countdown to initial value
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval); // Cleanup the interval on component unmount or when isCounting changes
    }, [isCounting]);

    const acceptPayment = (e) => {
        e.preventDefault();
        window.location.href = "/user/transactions";
    };

    const SubmitChange = () => {
        const selectedAmount = DataBank?.recharge_amounts.find((item) => item.id === selectedId);
        if (selectedAmount) {
            showPayment({ status: true })
            setDataPay({
                account_code: DataBank.account_code,
                account_name: DataBank.account_name,
                account_number: DataBank.account_number,
                datapayment: null,
                name_account: DataBank.name_account,
                contentz: DataBank.contentz,
                dataAmount: selectedAmount
            });

            if (!isCounting) {
                setIsCounting(true);
            }
        }
    }

    // console.log(countdown);

    const MyChange = ({
        receive,
        amount,
        id,
        bonus,
        handleClick,
    }) => {
        let nf = new Intl.NumberFormat();
        const myId = 123;
        const total =
            bonus > 0
                ? Number(receive) + (Number(receive) * Number(bonus)) / 100
                : receive;

        return (
            <div className="col">
                <div className="w-100 fw-semibold cursor-pointer">
                    <div
                        key={myId}
                        onClick={handleClick}
                        className={`recharge-method-item position-relative ${id === selectedId ? "active" : ""
                            }`}
                        style={{ height: "90px" }}
                    >
                        <div>{nf.format(Number(amount))}đ</div>
                        <div className="center-text text-danger">
                            <span>Nhận</span>
                        </div>
                        <div className="text-primary">
                            {nf.format(Number(total))}P
                        </div>
                        {bonus <= 0 ? (
                            ""
                        ) : (
                            <span
                                className="text-white position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                                style={{ zIndex: 1 }}
                            >
                                +{bonus}%
                            </span>
                        )}
                        {/*  */}
                    </div>
                </div>
            </div>
        );
    };

    const SendApi = async () => {

        try {
            const response = await CustomAxios(Config.walletinfo + "?type=1", {
            }, {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200) {
                if (response.data.status === true) {
                    setDataBank(response.data);
                }
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/home";
        }
    }

    const copyToText = (elementId) => {
        const contentz = document.getElementById(elementId);
        const textToCopy = contentz.value;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                Notiflix.Notify.success(`Copy ${textToCopy}`);
            })
            .catch((error) => {
                Notiflix.Notify.failure(`Không thể copy`);
            });
    }

    const copyToText1 = (e) => {
        e.preventDefault();
        copyToText('contentz');
    }

    const copyToText2 = (e) => {
        e.preventDefault();
        copyToText('account_number');
    }

    useEffect(() => {
        if (access_token) {
            SendApi();
        } else {
            window.location.href = "/home"
        }
    }, [access_token])

    const Quadrants = () => {
        const handleClick = (id) => () => {
            setSelectedId(id);
        };

        const data = DataBank?.recharge_amounts;
        if (data) {
            return (
                <React.Fragment>
                    {data.map((item) => (
                        <MyChange
                            key={item.id}
                            {...item}
                            handleClick={handleClick(item.id)}
                        />
                    ))}
                </React.Fragment>
            );
        }

        return (
            <div className="text-center"><img style={{ maxWidth: "75px" }} src={LoadingImg}></img></div>
        )

    };

    const MocNaps = () => {
        if (Payment.status === true) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="col-md-8 mt-3">
                        <div className="fs-5 fw-semibold text-center">Thanh toán</div>
                        <div>
                            <div>
                                <div className="table-responsive-sm">
                                    <table className="fw-semibold mt-3 table">
                                        <tbody>
                                            <tr>
                                                <td>Ngân hàng</td>
                                                <td>{DataPay.name_account}</td>
                                            </tr>
                                            <tr>
                                                <td>Chủ tài khoản</td>
                                                <td>{DataPay.account_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Số tài khoản</td>
                                                <td>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" readOnly={true} id="account_number" defaultValue={DataPay.account_number} />
                                                        <button type="button" className="btn btn-primary" onClick={copyToText2}>Copy</button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Số tiền</td>
                                                <td>{formatInt(DataPay.dataAmount.amount)}đ</td>
                                            </tr>
                                            <tr>
                                                <td>Nội dung</td>
                                                <td>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" readOnly={true} id="contentz" defaultValue={DataPay.contentz} />
                                                        <button type="button" className="btn btn-primary" onClick={copyToText1}>Copy</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-center fw-semibold fs-5">Quét mã để thanh toán</div>
                                {/* <div className="text-center mt-2"><img src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=2|99|${DataPay.account_number}|||0|0|${DataPay.dataAmount.amount}|${DataPay.account_content}|transfer_p2p`} alt="" style={{ height: '250px' }} /> */}
                                <div className="text-center mt-2"><img src={`https://img.vietqr.io/image/${DataPay.account_code}-${DataPay.account_number}-qr_only.jpg?amount=${DataPay.dataAmount.amount}&addInfo=${DataPay.contentz}&accountName=${DataPay.account_name}`} alt="" style={{ height: '250px' }} />
                                </div>

                            </div>
                            <div className="text-center mt-3"><button type="button" className="w-50 rounded-3 btn btn-primary btn-sm" onClick={acceptPayment} disabled={isCounting}>Xác nhận ({countdown})</button>
                                <div className="mt-2"><small className="fw-semibold"><Link to="/user/transactions">Lịch sử giao dịch</Link></small></div>
                            </div>
                            <div className="mt-4"><small className="fw-semibold">Lưu ý khi thanh toán: Giao dịch trên hoàn toàn được kiểm duyệt
                                tự động, yêu cầu kiểm tra kỹ nội dung chuyển tiền trước khi thực hiện chuyển. Nếu ghi thiếu, sai
                                hoặc quá 10 phút không thấy cộng tiền, các bạn hãy liên hệ với <Link target="_blank"
                                    to="/community" rel="noreferrer">Fanpage</Link> để được hỗ
                                trợ</small></div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="d-flex justify-content-center">
                    <div className="col-md-8 mt-3">
                        <div className="fs-5 fw-semibold text-center">Chọn mốc nạp</div>
                        {/* {
                            DataBank === null ?
                                <>
                                    <div className="text-center text-danger my-4 fw-semibold h6">Hình thức nạp tiền này hiện tại đang bảo
                                        trì, vui lòng thử hình thức khác</div>
                                    <div className="row text-center justify-content-center row-cols-2 row-cols-lg-3 g-2 g-lg-2 my-1 mb-2">
                                    </div>
                                </>
                                : null
                        } */}
                        <div>
                            <div className="fw-semibold my-3 text-center"><span className="text-danger">{DataBank?.svipAlert?.alert ?? null}</span></div>
                            <div className="row text-center justify-content-center row-cols-2 row-cols-lg-3 g-2 g-lg-2 my-1 mb-2">
                                <Quadrants />
                            </div>

                            <div className="text-center mt-3"><button type="button"
                                className="w-50 rounded-3 btn btn-primary btn-sm" onClick={SubmitChange}>Thanh toán</button>
                                <div className="mt-2"><small className="fw-semibold"><Link to="/user/transactions">Lịch sử giao
                                    dịch</Link></small></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="card">
            <div className="card-body">
                <div>
                    <div className="fs-5 fw-semibold text-center">Chọn hình thức nạp</div>
                    <div className="row text-center justify-content-center row-cols-2 row-cols-lg-5 g-2 g-lg-2 my-1 mb-2">
                        <div className="col"><Link className="w-100 fw-semibold" to="/recharge/momo">
                            <div className="recharge-method-item"><img alt="method" src={momo}
                                data-pin-no-hover="true" /></div>
                        </Link></div>
                        <div className="col"><Link className="w-100 fw-semibold" to="/recharge/bank">
                            <div className="recharge-method-item active"><img alt="method" src={bank}
                                data-pin-no-hover="true" /></div>
                        </Link></div>
                        <div className="col"><Link className="w-100 fw-semibold" to="/recharge/card">
                            <div className="recharge-method-item"><img alt="method" src={card}
                                data-pin-no-hover="true" /></div>
                        </Link></div>
                    </div>
                </div>

                <MocNaps />

            </div>
        </div>
    )
}
export default RechargeBank;