const API = process.env.REACT_APP_API_ENDPOINT ?? "";
export const Config = {
    login: API + "/api/auth/login",
    register: API + "/api/auth/register",
    active: API + "/api/active",
    verify: API + "/api/verify",
    walletinfo: API + "/api/recharge/wallet-info",
    exchange: API + "/api/exchange/package",
    exchange_topup: API + "/api/exchange/topup",
    transaction: API + "/api/transaction",
    cardsetting: API + "/api/card/settings",
    cardhistory: API + "/api/card/history",
    cardstore: API + "/api/card/store",
    community: API + "/api/community",
    download: API + "/api/download",
    geturldownload: API + "/api/geturldownload",
    topnap: API + "/api/top/",
    changepass: API + "/api/user/change-password",
    categories: API + "/api/categories/",

    // get posst
    categoriesview: API + "/api/ncategories/",
    getcategories: API + "/api/ncategories",
    postview: API + "/api/npost/",
    getpost: API + "/api/npost",
    checkevent: API + "/api/event/check",
    sendemail: API + "/api/user/password/resetpass",
    changeemail: API + "/api/user/changeemail"

};

export const PusherConfig = {
    key: "faa7195d1304980a2457",
    cluster: "ap1",
    channelName: window.location.hostname
}