import React, { useEffect, useState } from 'react';
import { CustomAxios } from '../../../Service/CustomAxios';
import { Config } from '../../../Config';
import LoadingImg from "../../../img/loading.gif";

const Categories = () => {
    let stringToRemove = "/categories/";
    let resultString = window.location.pathname.replace(stringToRemove, "");
    const [datane, setdata] = useState({});
    useEffect(() => {
        return () => {
            document.title = "Danh mục";
        };
    }, []);

    const SendApi = async () => {
        try {
            const response = await CustomAxios(Config.categories + resultString, {
                type: resultString,
            }, {
                Authorization: `Bearer `,
                "Content-Type": 'application/json'
            }, 'POST');
            if (response.status === 200 && response.data.status === true) {
                setdata(response.data);
            }
        } catch (error) {
            // localStorage.clear();
            // window.location.href = "/home";
        }
    };

    useEffect(() => {
        SendApi();
    }, [resultString]);

    const formatInt = (e) => {
        const formattedAmount = new Intl.NumberFormat();
        return formattedAmount.format(e);
    };

    if (datane.status === false) {
        return (
            <div className="fs-5 fw-semibold text-center">
                <h6 style={{ color: "rgb(0 0 0)" }}>
                    Không có ai đứng top
                </h6>
            </div>
        );
    }

    const renderTable = () => {
        if (datane.type === "listsvip") {
            return (
                <table className="table align-middle table-row-dashed gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>Mốc</th>
                            <th>PCoin</th>
                            <th>VIP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {datane.data.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{formatInt(item?.amount)}</td>
                                <td>{item?.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }

        return (
            <div className="text-center"><img style={{ maxWidth: "75px" }} src={LoadingImg}></img></div>
        ); // Return null if type is not recognized
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="table-responsive">{renderTable()}</div>
            </div>
        </div>
    );
};

export default Categories;